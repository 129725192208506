
import Vue from "vue";

export default Vue.extend({
  name: "CalendarForm",
  data: () => ({
    formValid: false,
    selectedAppointmenttypes: [],
  }),
  computed: {
    customerData() {
      return {
        new_client: {
          value: "new",
          type: "select",
          label: "Kundentyp",
          items: [
            { text: "Neukunde", value: "new" },
            { text: "Bestandskunde", value: "old" },
          ],
          cols: 12,
        },
        salutation: {
          value: "Herr",
          type: "select",
          items: [
            { text: "Herr", value: "Herr" },
            { text: "Frau", value: "Frau" },
            { text: "Familie", value: "Familie" },
            { text: "ohne Anrede", value: "" },
          ],
          label: "Anrede",
          cols: 4,
        },
        firstname: {
          value: "",
          type: "text",
          label: "Vorname",
          cols: 4,
        },
        lastname: {
          value: "",
          type: "text",
          label: "Nachname",
          cols: 4,
          rules: (v: any) => {
            return !!v || v === 0 || "Dies ist ein Pflichtfeld";
          },
        },
        street: {
          value: "",
          type: "text",
          label: "Straße",
          cols: 12,
          rules: (v: any) => {
            return !!v || v === 0 || "Dies ist ein Pflichtfeld";
          },
        },
        zip: {
          value: "",
          type: "text",
          label: "Postleitzahl",
          cols: 4,
          rules: (v: any) => {
            return !!v || v === 0 || "Dies ist ein Pflichtfeld";
          },
        },
        city: {
          value: "",
          type: "text",
          label: "Ort",
          cols: 8,
          rules: (v: any) => {
            return !!v || v === 0 || "Dies ist ein Pflichtfeld";
          },
        },
        email: {
          value: "",
          type: "text",
          label: "E-Mail",
          cols: 6,
          rules: (v: any) => {
            return (
              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                v
              ) || "Bitte überprüfen Sie die E-Mail Adresse"
            );
          },
        },
        phone: {
          value: "",
          type: "text",
          label: "Telefon",
          cols: 6,
          rules: (v: any) => {
            return !!v || v === 0 || "Dies ist ein Pflichtfeld";
          },
        },
        species: {
          type: "select",
          value: 1,
          items: [
            { value: 1, text: "Hund" },
            { value: 2, text: "Katze" },
            { value: 3, text: "Heimtier" },
            { value: 4, text: "Vogel" },
            { value: 5, text: "Wild-/Zootier" },
            { value: 6, text: "Fisch" },
            { value: 7, text: "Pferd" },
            { value: 8, text: "Rind" },
            { value: 9, text: "Schwein" },
            { value: 10, text: "Geflügel" },
            { value: 11, text: "Schaf, Ziege" },
            { value: 12, text: "sonstige Tiere" },
          ].filter((animal) => this.animals.includes(animal.value)),
          label: "Tierart",
          cols: 6,
        },

        petname: { value: "", type: "text", label: "Tiername", cols: 6 },
        appointmenttypes: {
          value: ["Unterwasserlaufband"],
          type: "checkbox",
          label: "Welche medizinische Versorgung benötigt Ihr Tier?",
          cols: 12,
          items: this.appointmenttypes,
        },
        reason: {
          value: "",
          type: "textarea",
          label: "Zusätzliche Mitteilung",
          cols: 12,
          rules: (v: any) => {
            return !!v || v === 0 || "Dies ist ein Pflichtfeld";
          },
        },
      };
    },
  },

  props: {
    appointment: {
      type: Array,
      default: () => [""],
    },
    animals: {
      type: Array,
      default: () => [1, 2, 3],
    },
    appointmenttypes: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    checkForm(): boolean {
      const inputsValid: boolean = (
        this.$refs.form as Vue & {
          validate: () => boolean;
        }
      ).validate();

      const postData = {
        //"doctor": this.doctorId,
        //"slot": "2022-09-06 11:00",
        reason:
          (this.selectedAppointmenttypes.length > 0
            ? this.selectedAppointmenttypes.join(", ") + " - "
            : "") + this.customerData.reason.value,
        //"token": "98a070eaa4b6ab5cba8d3e7c9ad80230",
        client: {
          new_client: 1,
          type: this.customerData.new_client.value,
          salutation: this.customerData.salutation.value,
          firstname: this.customerData.firstname.value,
          lastname: this.customerData.lastname.value,
          street: this.customerData.street.value,
          zip: this.customerData.zip.value,
          city: this.customerData.city.value,
          email: this.customerData.email.value,
          phone: this.customerData.phone.value,
          petname: this.customerData.petname.value,
          species: this.customerData.species.value,
        },
      };

      this.$emit("updatePostData", postData);
      return inputsValid;
    },
  },
});
