
import Vue from "vue";
import CalendarOverview from "@/components/CalendarOverview.vue";

export default Vue.extend({
  name: "HomeView",

  components: {
    CalendarOverview,
  },
  data: () => ({
    selectedDoctor: "Michelle",
    doctors: [
      {
        name: "Mila",
        id: [8],
        animals: [1, 2, 3, 12],
        types: [],
      },
      {
        name: "Michelle",
        id: [10, 11],
        animals: [1, 2, 7, 12],
        types: [3, 4, 5],
      },
    ],
  }),
  computed: {
    doc() {
      return this.doctors.find((doc) => doc.name === this.selectedDoctor);
    },
  },
});
