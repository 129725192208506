
import Vue from "vue";
import { IB_GET_APPOINTMENT_DATA, Slot } from "@/models/models";
import CalendarForm from "@/components/CalendarForm.vue";

export default Vue.extend({
  name: "CalendarOverview",
  components: {
    CalendarForm,
  },
  created() {
    const requestOptions = {
      method: "POST",
    };
    fetch(
      "https://darschin.inbehandlung.de/appointment/getdata",
      requestOptions
    )
      .then((response) => response.json())
      .then((ret) => {
        const data: IB_GET_APPOINTMENT_DATA = ret;
        if (data.success) {
          this.token = data.token;
          this.doctors = data.doctors.filter((doctor) =>
            this.doctorId.includes(doctor.id)
          ) || [{}];
          //this.doctors.forEach(doctor => { console.log(doctor) })
          this.selectedDoctor = this.doctors[0].id;
          this.doctor = this.doctors[0];
          this.appointmenttypes = data.appointmenttypes.filter((typ) =>
            this.types.includes(typ.id)
          ) || [{}];
        }
        this.loading = false;
      });
  },

  data: () => ({
    loading: true,
    requestSend: false,
    requestSuccessful: false,
    slot: "",
    slotPage: 1,
    slotsOnPage: 7,
    appointment: [""],
    appointmenttypes: [{}],
    token: "",
    doctors: [{}],
    selectedDoctor: 0,
    doctor: {},
    postData: {},
  }),
  props: {
    doctorId: {
      type: Array,
      default: () => [0],
    },
    animals: {
      type: Array,
      default: () => [1, 2, 3],
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    requestAppointment() {
      if (this.$refs.calendarForm.checkForm()) {
        const postData = {
          ...this.postData,
          doctor: this.selectedDoctor,
          slot: this.slot,
          token: this.token,
        };
        console.log(postData);

        const requestOptions = {
          method: "POST",
          body: JSON.stringify(postData),
        };
        fetch(
          "https://darschin.inbehandlung.de/appointment/book",
          requestOptions
        )
          .then((response) => response.json())
          .then((ret) => {
            this.requestSend = true;
            const data: IB_GET_APPOINTMENT_DATA = ret;
            if (data.success) {
              this.requestSuccessful = true;
            }
          });
      }
    },
    updatePostData(postData) {
      this.postData = postData;
    },
    selectDoctor() {
      const d = this.doctors.find(
        (doctor) => doctor.id === this.selectedDoctor
      );
      // Michelle Praxis nicht am Donnerstag
      if (this.selectedDoctor === 10) {
        d.slots = d.slots.filter(
          (slot) => !slot.date_formatted.startsWith("Do")
        );
      }
      // Michelle Hausbesuche nur Mo / Do
      if (this.selectedDoctor === 11) {
        d.slots = d.slots.filter(
          (slot) =>
            slot.date_formatted.startsWith("Mo") ||
            slot.date_formatted.startsWith("Do")
        );
      }
      this.doctor = d;
      this.clearSlot();
    },
    selectSlot(day: Slot, hour: string) {
      this.slot = [day.date, hour].join(" ");
      this.appointment = [day.date_formatted, hour];
    },
    clearSlot() {
      this.slot = "";
      this.appointment = [""];
      this.requestSend = false;
      this.requestSuccessful = false;
    },
  },
});
